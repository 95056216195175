import "./CSS/App.css";
import "./CSS/General.css";
import "./CSS/Adaptive.css";
import Image from "./black_bg.jpg";
import React, { useEffect, useState } from "react";

// import { Header } from "./components/Header/Header";
import { Cards } from "./components/Cards/Cards";
import { Cart } from "./components/Cart/Cart";
import Modal from "react-modal";
import { useFilterStore } from "./store/typesFilter";
import { Footer } from "./components/Footer/Footer";
import HeaderBurger from "./components/Header/HeaderBurger";

const API_URL = process.env.REACT_APP_API_URL;

Modal.setAppElement(document.getElementById("root"));

function App() {
  const [items, setItems] = useState([]);
  const { filter } = useFilterStore();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${API_URL}/api/front/cards`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Проблема с запросом");
        }

        const data = await response.json();

        setItems(data);
      } catch (e) {
        console.error("ERROR", e);
      }
    })();
  },[]);

  return (
    <div className="App">
      <HeaderBurger />
      <Cart />
      <Cards
        items={items.filter((item) => {
          if (filter === "ALL") {
            return true;
          }

          return item.category === filter;
        })}
      />
      <Footer />
    </div>
  );
}

export default App;
