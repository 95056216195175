import Modal from 'react-modal';
import { useState } from 'react';
import { useCartStore } from '../../store/cart';
import { useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Cart } from '../Cart/Cart';
Modal.setAppElement('#root');
// Стиль модалки
const customStyles = {
  overlay: {
    zIndex: '11',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '0',
    inset: '0',
  },
};

export function Card({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [size, setSize] = useState('');
  const { add } = useCartStore();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const addToCard = (item) => {
    if (item.sizes.length && !size) {
      return alert('Нужно выбрать размер перед покупкой!');
    }

    add({ ...item, size });
    setSize('');
  };

  if (isOpen) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='modal'
      >
        <div className='close-modal' onClick={closeModal}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            fill='currentColor'
            className='bi bi-x-lg'
            viewBox='0 0 16 16'
          >
            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
          </svg>
        </div>

        <div className='modal-card'>
          {item.carouselphotos ? (
            <div className='modal-card-slider'>
              <Splide>
                {item.carouselphotos.map((ph, key) => {
                  return (
                    <SplideSlide rewind={true} key={key}>
                      <div className='slider-img' key={key}>
                        <img src={ph} alt='' />
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          ) : (
            <div>No photo</div>
          )}
          <div className='modal-card-info'>
            <div className='card-title-modal'>{item.name}</div>
            <div className='card-description-modal'>{item.description}</div>
            <div className='size-grid'>
              {item.sizes.length ? (
                item.sizes.split(',').map((_size, key) => {
                  return (
                    <div
                      className={_size === size ? 'pick size-btn' : 'size-btn'}
                      key={key}
                      onClick={() => setSize(_size)}
                    >
                      {_size}
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
            <div className='card-before-purchase'>
              {item.category === 'SOON' ? (
                <div>
                  <div className='card-price-modal'>/{item.price} RUB</div>
                  <div
                    className='card-btn-buy-modal'
                    onClick={() => addToCard(item)}
                  >
                    PRE-ORDER NOW
                  </div>
                </div>
              ) : item.soldout ? (
                <div className='card-btn-soldout-modal'>SOLD OUT</div>
              ) : (
                <div>
                  <div className='card-price-modal'>/{item.price} RUB</div>
                  <div
                    className='card-btn-buy-modal'
                    onClick={() => addToCard(item)}
                  >
                    BUY NOW
                  </div>
                </div>
              )}

              <Cart />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className='col-6 col-md-6' onClick={openModal}>
      <div
        className='card-image'
        style={{
          backgroundImage: `url(${!hover ? item.mainphoto : item.backphoto})`,
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={openModal}
      ></div>

      <div className='card-details'>
        <div className='card-title'>{item.name}</div>
        <div className='card-price'>
          {item.category === 'SOON'
            ? 'Pre-order now'
            : item.soldout
            ? 'Sold out'
            : item.price + ' RUB'}
        </div>
      </div>
    </div>
  );
}
