const USER = "user";

export const saveUserToLS = (address, phone, telegram) => {
  let user = {
    address: address,
    phone: phone,
    telegram: telegram,
  };
  localStorage.setItem(USER, JSON.stringify(user));
};

export const getAddressFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.address;
};

export const getPhoneFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.phone;
};

export const getTelegramFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.telegram;
};
