import { useState } from "react";
import Modal from "react-modal";
import { useCartStore } from "../../store/cart";

//govnocode by o77777o
import { saveUserToLS } from "../../store/user";
import { getAddressFromLS } from "../../store/user";
import { getPhoneFromLS } from "../../store/user";
import { getTelegramFromLS } from "../../store/user";

const customStyles = {
  overlay: {
    zIndex: "11",
  },
  content: {
    width: "100%",
    height: "100%",
    padding: "0",
    inset: "0",
  },
};

export function Cart() {
  const { items, remove, clear } = useCartStore();
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState(getAddressFromLS());
  const [phone, setPhone] = useState(getPhoneFromLS());
  const [telegram, setTelegram] = useState(getTelegramFromLS());
  const [error, setError] = useState("");

  const processCheckout = () => {
    if (!items.length) {
      setError("Добавьте товар");
      return;
    }

    if (!address || address.length < 10) {
      setError("Введите адрес доставки");
      return;
    }

    if (!phone || phone.length < 10) {
      setError("Введите номер телефона");
      return;
    }

    if (!telegram || telegram.length <= 2) {
      setError("Введите телеграм для связи");
      return;
    }

    setError("");

    const request = async () => {
      try {
        const res = await fetch("https://api.nedostupnost.com/order/new", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order: {
              info: {
                address,
                phone,
                telegram,
              },
              items,
            },
          }),
        });

        if (res.ok) {
          alert("Заказ оформлен!");
          clear();

          return;
        }

        throw new Error(`${res.status} ${res.statusText}`);
      } catch (err) {
        alert(`Что-то пошло не так 😓 попробуем еще раз?`);
      }
    };

    request().catch(console.error);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    saveUserToLS(address, phone, telegram);
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal"
      >
        <div className="close-modal" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </div>

        <div className="shopping-cart">
          <div className="cart-all">
            <div className="shopping-cart-title-modal">Корзина</div>
            <div className="cart-items">
              {items.map((item, key) => (
                <div className="shopping-cart-item" key={key}>
                  <div className="item-first">
                    <div>{item.name}</div>
                    <div
                      className="cart-btn-remove"
                      onClick={() => remove(item)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="white"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </div>
                  </div>
                  <div className="cart-item-price">{item.price} RUB</div>
                  <div className="cart-item-size">
                    {!item.size ? "" : "Размер " + item.size}
                  </div>
                </div>
              ))}
            </div>

            <div className="shopping-cart-title-modal">
              TOTAL:{" "}
              {items.reduce((prev, curr) => prev + parseInt(curr.price), 0)} RUB
            </div>

            <div className="cart-inputs">
              <div className="input-section">
                <div className="input-name">Адрес доставки</div>
                <div className="input-info">в пределах РФ</div>
                <input
                  value={address}
                  autoComplete
                  type="address"
                  className="cart-input"
                  placeholder="город Москва, ул Ленина 1..."
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="input-section">
                <div className="input-name">Номер телефона</div>
                <div className="input-info">для подтверждения заказа</div>
                <input
                  value={phone}
                  autoComplete
                  type="number"
                  className="cart-input"
                  placeholder="+7981123321"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="input-section">
                <div className="input-name">Ник личного профиля в Tелеграм</div>
                <div className="input-info">
                  чтобы мы могли связаться по уточнению доставки
                </div>
                <input
                  value={telegram}
                  className="cart-input"
                  placeholder="Ссылка или ник"
                  onChange={(e) => setTelegram(e.target.value)}
                />
              </div>

              <button className="cart-btn-checkout" onClick={processCheckout}>
                Place Order
              </button>
              {error ? <p className="error">{error}</p> : <div></div>}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="cart-modal" onClick={openModal}>
      <svg
        color="white"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        className="bi bi-cart"
        viewBox="0 0 16 16"
      >
        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"></path>
      </svg>
      {items.length ? (
        <div className="cart-counter">{items.length}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
