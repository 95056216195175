import React, { useState, useEffect } from "react";
import { useFilterStore } from "../../store/typesFilter";

const API_URL = process.env.REACT_APP_API_URL;

export function Burger() {
  const [openMenu, setOpenMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const { filter, setFilter } = useFilterStore();

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await fetch(`${API_URL}/api/front/categories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Проблема с запросом");
        }

        const data = await response.json();
        setCategories(data);
      } catch (e) {
        console.error("Ошибка при выполнении запроса:", e);
      }
    }

    fetchCategories();
  }, []);

  return (
    <div className="burger">
      <svg
        onClick={() => setOpenMenu(!openMenu)}
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill={openMenu ? "Red" : "white"}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
        />
      </svg>
      {openMenu && (
        <div className="burger-panel">
          <div
            onClick={() => setFilter("ALL")}
            className={`nav_item burger ${filter === "ALL" ? "active" : ""}`}
          >
            ALL
          </div>
          {categories.map((el) => (
            <div
              onClick={() => setFilter(el.name)}
              className={`nav_item burger ${filter === el.name ? "active" : ""}`}
              key={el.id}
            >
              {el.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
