import { create } from 'zustand'


// export const GOODS_CATEGORIES = {
//   ALL: 'ALL',
//   T_SHIRTS: 'T_SHIRTS',
//   HOODIE: 'HOODIE',
//   SWEATSHIRTS: 'SWEATSHIRTS',
//   TROUSERS: 'TROUSERS',
//   KIMONO: 'KIMONO',
//   BOMBER: 'BOMBER',
//   ACCESSORIES: 'ACCESSORIES',
// };

export const useFilterStore = create((set) => ({
  filter: 'ALL',

  removeFilter: () => set(() => ({ filter: 'ALL' })),

  setFilter: (filter) => set(() => ({ filter })),
}));